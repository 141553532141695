import axios, {
  AxiosRequestConfig,
  AxiosResponse,
  CustomParamsSerializer,
} from 'axios';
import { stringify } from 'qs';

const instance = axios.create({
  paramsSerializer: stringify as CustomParamsSerializer,
});

let api: Record<string, string>;
let authorization: string;

export const initRequest = ({
  accessToken,
  api: apiOption,
}: {
  accessToken: string;
  api: Record<string, string>;
}): void => {
  api = apiOption;
  authorization = `Bearer ${accessToken}`;
};

const request = <D>(config: AxiosRequestConfig) =>
  instance(config).then((res: AxiosResponse<D>) => res.data);

export const surveyor = <D>(config: AxiosRequestConfig): Promise<D> =>
  request({
    baseURL: api.surveyor,
    headers: { authorization },
    ...config,
  });
